var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "btn btn--raised btn--file",
      staticStyle: { position: "relative" },
      attrs: { id: _vm.id, type: "button" },
    },
    [
      _c(
        "div",
        {
          staticClass: "btn__content",
          attrs: { id: _vm.id + "label", "aria-hidden": "" },
        },
        [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]
      ),
      _vm._v(" "),
      _c("input", {
        attrs: { "aria-label": _vm.label, type: "file" },
        on: { change: _vm.upload },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }