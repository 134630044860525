import { render, staticRenderFns } from "./Identicon.vue?vue&type=template&id=3987069a&"
import script from "./Identicon.vue?vue&type=script&lang=js&"
export * from "./Identicon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/steineggerlab/foldseek-transcript-server/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3987069a')) {
      api.createRecord('3987069a', component.options)
    } else {
      api.reload('3987069a', component.options)
    }
    module.hot.accept("./Identicon.vue?vue&type=template&id=3987069a&", function () {
      api.rerender('3987069a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "frontend/Identicon.vue"
export default component.exports