var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "search-component",
      attrs: { "grid-list-md": "", fluid: "", "px-2": "", "py-1": "" },
    },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", md8: "" } },
            [
              _c(
                "panel",
                {
                  staticClass: "query-panel d-flex fill-height",
                  attrs: { "fill-height": "" },
                },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v("\n                    Queries\n                "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "toolbar-extra" },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { "open-delay": "300", top: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                return [
                                  _c("v-icon", _vm._g({}, on), [
                                    _vm._v(_vm._s(_vm.$MDI.HelpCircleOutline)),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$STRINGS.QUERIES_HELP)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "content" },
                    [
                      _c("v-textarea", {
                        staticClass: "marv-bg mono",
                        attrs: {
                          "aria-label": _vm.$STRINGS.QUERIES_HELP,
                          "hide-details": "",
                          placeholder: _vm.$STRINGS.QUERIES_HELP,
                          spellcheck: "false",
                        },
                        on: {
                          dragover: function ($event) {
                            $event.preventDefault()
                          },
                          drop: function ($event) {
                            return _vm.fileDrop($event)
                          },
                        },
                        model: {
                          value: _vm.query,
                          callback: function ($$v) {
                            _vm.query = $$v
                          },
                          expression: "query",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "actions" },
                        [
                          !_vm.$ELECTRON
                            ? _c(
                                "v-dialog",
                                {
                                  attrs: {
                                    absolute: "",
                                    disabled: _vm.searchDisabled,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.searchDisabled,
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  "\n                                cURL Command\n                            "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1444602170
                                  ),
                                  model: {
                                    value: _vm.showCurl,
                                    callback: function ($$v) {
                                      _vm.showCurl = $$v
                                    },
                                    expression: "showCurl",
                                  },
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-card-title", [
                                        _c("div", { staticClass: "text-h5" }, [
                                          _vm._v("cURL Command"),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-card-text", [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(_vm.$STRINGS.CURL_INTRO) +
                                            "\n                            "
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "code",
                                          [
                                            _vm._v(
                                              "curl -X POST -F q=@PATH_TO_FILE "
                                            ),
                                            _vm.email
                                              ? _c("span", [
                                                  _vm._v(
                                                    "-F 'email=" +
                                                      _vm._s(_vm.email) +
                                                      "'"
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(
                                              " -F 'mode=" +
                                                _vm._s(_vm.mode) +
                                                "' "
                                            ),
                                            _vm._l(
                                              _vm.database,
                                              function (path, i) {
                                                return _c("span", { key: i }, [
                                                  _vm._v(
                                                    "-F 'database[]=" +
                                                      _vm._s(path) +
                                                      "' "
                                                  ),
                                                ])
                                              }
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.origin() + "/api/ticket"
                                                )
                                            ),
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(
                                          "\n                                Refer to the "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "https://search.mmseqs.com/docs/",
                                              target: "_blank",
                                              rel: "noopener",
                                            },
                                          },
                                          [_vm._v("API documentation")]
                                        ),
                                        _vm._v(
                                          ", on how to check the status and fetch the result.\n                            "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  _vm.showCurl = false
                                                },
                                              },
                                            },
                                            [_vm._v("Close")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$APP == "foldseek"
                            ? _c("load-acession-button", {
                                on: {
                                  select: function ($event) {
                                    _vm.query = $event
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("file-button", {
                            attrs: {
                              id: "file",
                              label: _vm.$STRINGS.UPLOAD_LABEL,
                            },
                            on: { upload: _vm.upload },
                          }),
                          _vm._v(" "),
                          _vm.$APP == "foldseek"
                            ? _c("PredictStructureButton", {
                                attrs: { query: _vm.query },
                                on: {
                                  predict: function ($event) {
                                    _vm.query = $event
                                  },
                                },
                                model: {
                                  value: _vm.predictable,
                                  callback: function ($$v) {
                                    _vm.predictable = $$v
                                  },
                                  expression: "predictable",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { xs12: "", md4: "" } },
            [
              _c("panel", { attrs: { header: "Search Settings" } }, [
                _c(
                  "div",
                  { attrs: { slot: "content" }, slot: "content" },
                  [
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { "open-delay": "300", top: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "label",
                                      _vm._g({}, on),
                                      [
                                        _vm._v("Databases "),
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              staticStyle: {
                                                "margin-top": "-3px",
                                              },
                                              attrs: {
                                                color: "#FFFFFFB3",
                                                small: "",
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$MDI.HelpCircleOutline)
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _vm._v(" "),
                            _vm.$ELECTRON || _vm.hideEmail
                              ? _c("span", [
                                  _vm._v(
                                    "Choose the databases to search against and the result mode."
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "Choose the databases to search against, the result mode, and optionally an email to notify you when the job is done."
                                  ),
                                ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.databases.length == 0
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              class: [
                                "alert",
                                { "alert-info": !_vm.dberror },
                                { "alert-danger": _vm.dberror },
                              ],
                            },
                            [
                              _vm.dberror
                                ? _c("span", [
                                    _vm._v(
                                      "Could not query available databases!"
                                    ),
                                  ])
                                : _vm.dbqueried == false
                                ? _c("span", [_vm._v("Loading databases...")])
                                : _c("span", [
                                    _vm._v(
                                      "\n                                No databases found! "
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.$ELECTRON
                                      ? _c(
                                          "span",
                                          [
                                            _vm._v(
                                              "\n                                    Go to "
                                            ),
                                            _c(
                                              "router-link",
                                              { attrs: { to: "preferences" } },
                                              [_vm._v("Preferences")]
                                            ),
                                            _vm._v(
                                              " to add a database.\n                                "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                            ]
                          ),
                        ])
                      : _vm._l(_vm.databases, function (db, index) {
                          return _c("v-checkbox", {
                            key: index,
                            attrs: {
                              value: db.path,
                              label: db.name + " " + db.version,
                              "append-icon":
                                db.status == "ERROR" || db.status == "UNKNOWN"
                                  ? _vm.$MDI.AlertCircleOutline
                                  : db.status == "PENDING" ||
                                    db.status == "RUNNING"
                                  ? _vm.$MDI.ProgressWrench
                                  : undefined,
                              disabled: db.status != "COMPLETE",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.database,
                              callback: function ($$v) {
                                _vm.database = $$v
                              },
                              expression: "database",
                            },
                          })
                        }),
                    _vm._v(" "),
                    _vm.databasesNotReady
                      ? _c("div", { staticClass: "alert alert-info mt-1" }, [
                          _c("span", [_vm._v("Databases are loading...")]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-radio-group",
                      {
                        model: {
                          value: _vm.mode,
                          callback: function ($$v) {
                            _vm.mode = $$v
                          },
                          expression: "mode",
                        },
                      },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { "open-delay": "300", top: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "label",
                                      _vm._g({}, on),
                                      [
                                        _vm._v("Mode "),
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              staticStyle: {
                                                "margin-top": "-3px",
                                              },
                                              attrs: {
                                                color: "#FFFFFFB3",
                                                small: "",
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$MDI.HelpCircleOutline)
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _vm._v(" "),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.$STRINGS.MODE_HELP),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.$STRINGS.MODE_COUNT - 0, function (i) {
                          return _c("v-radio", {
                            key: i,
                            attrs: {
                              "hide-details": "",
                              value: _vm.$STRINGS["MODE_KEY_" + i],
                              label: _vm.$STRINGS["MODE_TITLE_" + i],
                            },
                          })
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("TaxonomyAutocomplete", {
                      model: {
                        value: _vm.taxFilter,
                        callback: function ($$v) {
                          _vm.taxFilter = $$v
                        },
                        expression: "taxFilter",
                      },
                    }),
                    _vm._v(" "),
                    !_vm.$ELECTRON && !_vm.hideEmail
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { "open-delay": "300", top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              label:
                                                "Notification Email (Optional)",
                                              placeholder: "you@example.org",
                                            },
                                            model: {
                                              value: _vm.email,
                                              callback: function ($$v) {
                                                _vm.email = $$v
                                              },
                                              expression: "email",
                                            },
                                          },
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              968467921
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v("Send an email when the job is done."),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "primary",
                          block: "",
                          large: "",
                          disabled: _vm.searchDisabled,
                        },
                        on: { click: _vm.search },
                      },
                      [
                        _c("v-icon", [_vm._v(_vm._s(_vm.$MDI.Magnify))]),
                        _vm._v("Search"),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.errorMessage != ""
                      ? _c("div", { staticClass: "v-alert red mt-2" }, [
                          _c("span", [_vm._v(_vm._s(_vm.errorMessage))]),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                { attrs: { rounded: "0" } },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "pb-0 mb-0",
                      attrs: { "primary-title": "" },
                    },
                    [
                      _c("div", { staticClass: "text-h5 mb-0" }, [
                        _vm._v("Reference"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-title",
                    {
                      staticClass: "pt-0 mt-0",
                      attrs: { "primary-title": "" },
                    },
                    [
                      _c("p", {
                        staticClass: "text-subtitle-2 mb-0",
                        domProps: { innerHTML: _vm._s(_vm.$STRINGS.CITATION) },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }