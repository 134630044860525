var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "panel-root",
        _vm.elevation != null ? "elevation-" + _vm.elevation : null,
      ],
    },
    [
      !!_vm.$slots["header"] || !!_vm.header
        ? _c(
            "v-toolbar",
            { attrs: { text: "", dense: "", dark: "" } },
            [
              _c(
                "span",
                { staticClass: "text-h6 align-end" },
                [
                  _vm.$slots["header"]
                    ? _vm._t("header")
                    : [_vm._v(_vm._s(_vm.header))],
                ],
                2
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm._t("toolbar-extra"),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        {
          class: [
            "panel",
            { "d-flex": _vm.flex },
            { "force-fill-height": _vm.fillHeight },
          ],
          attrs: { rounded: "0" },
        },
        [
          _vm.$slots["desc"]
            ? _c(
                "v-card-text",
                { staticClass: "subheading justify" },
                [_vm._t("desc")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$slots["content"]
            ? _c(
                "v-card-text",
                { class: ["panel-content", "justify", { "d-flex": _vm.flex }] },
                [_vm._t("content")],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }