var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          ref: "drawer",
          attrs: {
            stateless: "",
            app: "",
            permanent: "",
            clipped: "",
            "mini-variant": _vm.mini,
            "expand-on-hover": false,
          },
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                { attrs: { to: "/search" } },
                [
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v(_vm._s(_vm.$MDI.Magnify))])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Search")])],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("router-view", { attrs: { name: "sidebar" } }),
              _vm._v(" "),
              _c("history"),
              _vm._v(" "),
              _vm.$ELECTRON
                ? _c(
                    "v-list-item",
                    { attrs: { to: "/preferences" } },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v(_vm._s(_vm.$MDI.Tune))])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Preferences")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-app-bar",
        {
          class: ["ml-0", "pl-3", _vm.$ELECTRON ? "pt-2" : null],
          style: {
            "-webkit-app-region": _vm.$ELECTRON ? "drag" : null,
            "-webkit-user-select": _vm.$ELECTRON ? "none" : null,
          },
          attrs: {
            app: "",
            height: _vm.$ELECTRON ? "72px" : "48px",
            fixed: "",
            "clipped-left": "",
          },
          nativeOn: {
            dblclick: function ($event) {
              return _vm.electronHandleTitleBarDoubleClick()
            },
          },
        },
        [
          _c("v-app-bar-nav-icon", {
            attrs: { "input-value": !_vm.mini ? "activated" : undefined },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.toggleMini.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "v-app-bar-title",
            [
              _c(
                "router-link",
                {
                  staticStyle: { color: "inherit", "text-decoration": "none" },
                  attrs: { to: "/" },
                },
                [_vm._v(_vm._s(_vm.$STRINGS.APP_NAME) + " Search")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.$APP == "mmseqs"
            ? _c(
                "object",
                {
                  staticStyle: {
                    "margin-left": "8px",
                    display: "inline-block",
                    width: "38px",
                    height: "38px",
                    "vertical-align": "middle",
                  },
                  attrs: {
                    type: "image/svg+xml",
                    data: require("./assets/marv1.svg"),
                    "aria-hidden": "true",
                  },
                },
                [
                  _c("img", {
                    staticStyle: { "max-width": "100%" },
                    attrs: { src: require("./assets/marv1.png") },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$APP == "foldseek"
            ? _c("img", {
                staticStyle: {
                  "margin-left": "8px",
                  display: "inline-block",
                  width: "48px",
                  height: "48px",
                  "vertical-align": "middle",
                },
                attrs: {
                  src: require("./assets/marv-foldseek-small.png"),
                  "aria-hidden": "true",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          !_vm.$ELECTRON ? _vm._m(0) : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "v-toolbar-items",
      { staticClass: "hidden-sm-and-down" },
      _vm._l(_vm.$STRINGS.NAV_URL_COUNT - 0, function (i) {
        return _c(
          "v-btn",
          {
            key: i,
            attrs: {
              text: "",
              rel: "external noopener",
              target: "_blank",
              href: _vm.$STRINGS["NAV_URL_" + i],
            },
          },
          [_vm._v(_vm._s(_vm.$STRINGS["NAV_TITLE_" + i]))]
        )
      }),
      1
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }