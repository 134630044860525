var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { absolute: "", width: "unset" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c("v-btn", _vm._g({}, on), [
                _vm._v("\n            Load accession\n        "),
              ]),
            ]
          },
        },
      ]),
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("div", { staticClass: "text-h5" }, [_vm._v("Load accession")]),
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-select", {
                attrs: { items: _vm.sources, label: "Source" },
                model: {
                  value: _vm.source,
                  callback: function ($$v) {
                    _vm.source = $$v
                  },
                  expression: "source",
                },
              }),
              _vm._v(" "),
              _c("v-text-field", {
                ref: "accession",
                attrs: { label: "Accession" },
                on: {
                  keydown: function ($event) {
                    _vm.error = false
                  },
                },
                model: {
                  value: _vm.accession,
                  callback: function ($$v) {
                    _vm.accession = $$v
                  },
                  expression: "accession",
                },
              }),
              _vm._v(" "),
              _vm.error
                ? _c(
                    "v-sheet",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center",
                      },
                      attrs: {
                        color: "error",
                        rounded: "",
                        width: "100%",
                        height: "48",
                      },
                    },
                    [_vm._v(_vm._s(_vm.error))]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  nativeOn: {
                    click: function ($event) {
                      _vm.show = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    text: "",
                    disabled: this.accession.length == 0 || _vm.loading,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.load.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Load")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }